import { styled } from 'linaria/react';
import React from 'react';
import { Borders } from '../../Shared/Borders/Borders';
import { ContainerCustom } from '../../Shared/ConatinerCustom/ContainerCustom';
import { HeadingWithNote } from '../../Shared/HedingWithNote/HeadingWithNote';
import { Section } from '../../Shared/Section/Section';
import { HeadingTwo } from '../../Shared/Typography/HeadingTwo/HeadingTwo';
import { useKmwInstructors } from '../hooks/useKmwInstructors';
import { KmwInstructorProfile } from './components/KmwInstructorProfile';

export interface KmwInstructorProfilesProps {
  className?: string;
}

const KmwInstructorProfilesInner = (props: KmwInstructorProfilesProps) => {
  const instructors = useKmwInstructors();
  console.log(instructors);

  return (
    <Section className={props.className}>
      <ContainerCustom>
        <Borders bottomLeft>
          <HeadingWithNote
            heading="PROFILY INŠTRUKTOROV"
            note="Všetci naši inštruktori sú certifikovaní medzinárodnou organizáciou Krav Maga Global. Absolvovali náročnú a dlhú cestu, aby ti mohli odovzdať získané skúsenosti."
          />
          <div className="content">
            {instructors.map(instructor => (
              <KmwInstructorProfile
                key={instructor.name}
                name={instructor.name}
                text={instructor.text}
                position={instructor.position}
              />
            ))}
          </div>
        </Borders>
      </ContainerCustom>
    </Section>
  );
};

export const KmwInstructorProfiles = styled(KmwInstructorProfilesInner)``;

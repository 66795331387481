import React from 'react';
import { KmwGlobal } from '../components/kmw/KmwGlobal/KmwGlobal';
import { KmwHistory } from '../components/kmw/KmwHistory/KmwHistory';
import { KmwInstructorProfiles } from '../components/kmw/KmwInstructorProfiles/KmwInstructorProfiles';
import { KMWInstructors } from '../components/kmw/KMWInstructors/KMWInstructors';
import { KmwOurClub } from '../components/kmw/KmwOurClub/KmwOurClub';
import { KmwVisualFaq } from '../components/kmw/KmwVisualFaq/KmwVisualFaq';
import Layout from '../components/Shared/Layout/Layout';
import { SEO } from '../components/Shared/SEO/SEO';

const KravMagaWarriorPage = () => {
  return (
    <Layout>
      <SEO title={'Warrior'}  />
      <KmwVisualFaq />
      <KMWInstructors />
      <KmwInstructorProfiles />
      <KmwOurClub />
      <KmwHistory />
      <KmwGlobal />
    </Layout>
  );
};

export default KravMagaWarriorPage;

import GatsbyImage, { FluidObject } from 'gatsby-image';
import { styled } from 'linaria/react';
import React from 'react';
import { muiTheme } from '../../../Shared/Theme/Theme';
import { Instructor } from '../../hooks/useKmwInstructors';
import { InstructorCardContent } from './InstructorCardContent';

export interface InstructorCardProps extends Instructor {
  className?: string;
}

const InstructorCardInner = (props: InstructorCardProps) => {
  const { image, level, name, description } = props;
  return (
    <article className={props.className}>
      <div className="image">
        <GatsbyImage fluid={image} />
      </div>
      <InstructorCardContent {...props} />
    </article>
  );
};

export const InstructorCard = styled(InstructorCardInner)`
  .image {
    z-index: -1;
    position: relative;
    overflow: hidden;

    ${muiTheme.breakpoints.down('sm')} {
      height: 400px;
    }
  }
`;

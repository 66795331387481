import { Link } from 'gatsby';
import { styled } from 'linaria/react';
import React from 'react';
import { routes } from '../../../../lib/routes';
import { KVButton } from '../../../Shared/KVButton/KVButton';
import { SponsorImagesVertical } from '../../../Shared/SponsorImagesVertical/SponsorImagesVertical';
import { HeadingThree } from '../../../Shared/Typography/HeadingThree/HeadingThree';

export interface LeftPaneFooterProps {
  className?: string;
}

const LeftPaneFooterInner = (props: LeftPaneFooterProps) => {
  return (
    <div className={props.className}>
      <SponsorImagesVertical />
      <div className="signup">
        <HeadingThree className="heading dark">
          STAŇ SA KRAV MAGA WARRIOR!
        </HeadingThree>
        <Link to={routes.signUp.to}>
          <KVButton className="button">ZAPÍSAŤ SA DO KMW</KVButton>
        </Link>
      </div>
    </div>
  );
};

export const LeftPaneFooter = styled(LeftPaneFooterInner)`
  width: 80%;
  margin: auto;
  text-align: center;

  .button {
    margin-top: 10px;
  }
`;

import GatsbyImage from 'gatsby-image';
import { styled } from 'linaria/react';
import React from 'react';
import { HeadingWithNote } from '../../../Shared/HedingWithNote/HeadingWithNote';
import { useTrainingEquipmentImage } from '../../../Shared/SponsorImagesVertical/hooks/useTrainingEquipmentImage';

export interface KmwGlobalImageProps {
  className?: string;
}

const KmwGlobalImageInner = (props: KmwGlobalImageProps) => {
  const image = useTrainingEquipmentImage();
  return (
    <div className={props.className}>
      <HeadingWithNote
        heading={
          <>
            <a
              href="https://krav-maga.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GatsbyImage className="global-image" fluid={image} />
            </a>
          </>
        }
        note="Kliknutím na logo KMG navštívite stránku organizácie Krav Maga Global."
      />
    </div>
  );
};

export const KmwGlobalImage = styled(KmwGlobalImageInner)`
  width: 100%;
  display: flex;
  justify-content: center;

  .global-image {
    height: 320px;
    width: 350px;
  }
`;

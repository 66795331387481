import { styled } from 'linaria/react';
import React from 'react';
import { theme } from '../../../Shared/Theme/Theme';

export interface InstructorCardContentProps {
  level: string;
  name: string;
  description: string;
  className?: string;
}

const InstructorCardContentInner = (props: InstructorCardContentProps) => {
  const { level, name, description } = props;
  return (
    <div className={props.className}>
      <div className="level">úroveň: {level}</div>
      <div className="name">{name}</div>
      <div className="description">{description}</div>
    </div>
  );
};

export const InstructorCardContent = styled(InstructorCardContentInner)`
  padding: 25px;
  background: white;
  width: 80%;
  margin: 0 auto;
  margin-top: -30px;
  text-align: left;

  .level {
    font-size: 1.8rem;
    color: ${theme.color.primary};
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .name {
    font-size: 1.8rem;
    color: ${theme.color.primaryDark};
    font-weight: 600;
    margin-bottom: 20px;
  }
`;

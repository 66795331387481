import { Grid, Hidden } from '@material-ui/core';
import { useStaticQuery, graphql } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import { styled } from 'linaria/react';
import React, { useState } from 'react';
import { ContainerCustom } from '../../Shared/ConatinerCustom/ContainerCustom';
import { HeadingWithNote } from '../../Shared/HedingWithNote/HeadingWithNote';
import { Section } from '../../Shared/Section/Section';
import { theme } from '../../Shared/Theme/Theme';
import { HeadingTwo } from '../../Shared/Typography/HeadingTwo/HeadingTwo';
import { CurrentQuestino } from './components/CurrentQuestion';
import { BsChevronRight } from 'react-icons/bs';
import { LeftPaneFooter } from './components/LeftPaneFooter';
import { useKmwQuestions } from './hooks/useKmwQuestions';

export interface KmwVisualFaqProps {
  className?: string;
}

export interface Question {
  image: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
  html: string;
  title: string;
}

const QuestionOption = styled.div`
  display: flex;
  padding: 1.8rem 2.4rem;
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: 600;
  color: ${theme.color.primaryDark};
  margin-bottom: 20px;
  background-color: ${theme.color.gray};
  justify-content: space-between;

  &.active {
    background-color: ${theme.color.primary};
    color: ${theme.color.background};
  }
`;

const KmwVisualFaqInner = (props: KmwVisualFaqProps) => {
  const questions = useKmwQuestions();

  const [currentlySelectedType, setCurrentlySelectedType] = useState(
    Object.keys(questions)[0]
  );
  const changeType = (type: string) => () => setCurrentlySelectedType(type);

  return (
    <Section className={props.className}>
      <ContainerCustom>
        <HeadingWithNote
          heading={
            <HeadingTwo style={{ padding: 0 }}>Krav maga warrior</HeadingTwo>
          }
          note="Semináre, nepravidelné podujatia a akcie nájdeš na našej Facebook stránke."
        />
        <Grid container spacing={4}>
          <Grid item container md={4} xs={12} direction="column">
            <div className="picker">
              {Object.keys(questions).map(kitchenTypeIndex => (
                <QuestionOption
                  key={kitchenTypeIndex}
                  className={
                    kitchenTypeIndex === currentlySelectedType ? 'active' : ''
                  }
                  onClick={changeType(kitchenTypeIndex)}
                >
                  {kitchenTypeIndex} <BsChevronRight />
                </QuestionOption>
              ))}
              <Hidden smDown>
                <LeftPaneFooter />
              </Hidden>
            </div>
          </Grid>
          <Grid item container md={8} xs={12}>
            <CurrentQuestino question={questions[currentlySelectedType]} />
          </Grid>
        </Grid>
      </ContainerCustom>
    </Section>
  );
};

export const KmwVisualFaq = styled(KmwVisualFaqInner)`
  .picker {
    display: flex;
    flex: 1;
    flex-flow: column;
  }
  .image {
    width: 100%;
    transition: all 350ms ease-in-out;
  }
`;

import { styled } from 'linaria/react';
import React from 'react';
import { Borders } from '../../Shared/Borders/Borders';
import { ContainerCustom } from '../../Shared/ConatinerCustom/ContainerCustom';
import { HeadingWithNote } from '../../Shared/HedingWithNote/HeadingWithNote';
import { Section } from '../../Shared/Section/Section';
import { HeadingFour } from '../../Shared/Typography/HeadingFour/HeadingFour';
import { HeadingTwo } from '../../Shared/Typography/HeadingTwo/HeadingTwo';

export interface KmwOurClubProps {
  className?: string;
}

const KmwOurClubInner = (props: KmwOurClubProps) => {
  return (
    <Section className={props.className}>
      <ContainerCustom>
        <Borders topRight bottomRight>
          <div className="ourclub__content">
            <HeadingWithNote heading="NÁŠ KLUB A KRAV MAGA GLOBAL" />
            <HeadingFour>Klub Krav Maga Warrior</HeadingFour>
            <p>
              Klub Krav Maga Warrior sa historicky rozrastal od roku 2009 z
              pôvodného klubu Krav Maga Petržalka do dnešnej podoby. Krav Maga
              Warrior patrí do najväčšej a najprofesionálnejšej organizácie Krav
              Magy na svete, do organizácie KRAV MAGA GLOBAL (KMG) na Slovensku
              je zastúpená ako KMG Slovakia.
            </p>
            <HeadingFour>Krav Maga Global</HeadingFour>
            <p>
              Krav Maga Global (KMG) je uznávaná ako najprofesionálnejšia a
              medzinárodne najaktívnejšia organizácia Krav Maga, ktorá si
              získala dôveru bezpečnostných služieb i civilistov. KMG poskytuje
              výcvikové služby pre civilistov, vojenské aj bezpečnostné zložky,
              ochrany VIP, leteckých marshalov, tímov SWAT atď. Sídlo KMG je v
              Izraeli. Hlavným inštruktorom a prezidentom organizácie KMG je
              Eyal Yanilov, ktorý má ako jediný na svete stupeň Master 3, čo mu
              bolo udelené ešte Imrichom Lichtenfeldom, zakladateľom Krav Maga s
              koreňmi priamo z Bratislavy. Viac informácií ohľadne Eyala
              Yanilova a organizácie KMG nájdete na www.krav-maga.com.
            </p>
          </div>
        </Borders>
      </ContainerCustom>
    </Section>
  );
};

export const KmwOurClub = styled(KmwOurClubInner)`
  padding: 20px 0;
  .ourclub {
    &__content {
      padding: 30px 0;
    }
  }

  p {
    margin-bottom: 30px;
    margin-top: 10px;
  }
`;

import { styled } from 'linaria/react';
import React from 'react';
import { theme } from '../../../Shared/Theme/Theme';

export interface InstructorsKMWSideProps {
  className?: string;
}

const InstructorsKMWSideInner = (props: InstructorsKMWSideProps) => {
  return (
    <div className={props.className}>
      <span>KMW</span>
    </div>
  );
};

export const InstructorsKMWSide = styled(InstructorsKMWSideInner)`
  position: relative;

  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: -125px;
  bottom: 110px;

  span {
    font-size: 173px;
    font-weight: 700;
    transform: rotate(-90deg);

    -webkit-text-fill-color: ${theme.color.gray};
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: ${theme.color.grayDark};
  }
`;

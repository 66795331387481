import { styled } from 'linaria/react';
import React, { useState } from 'react';

export interface ContentRendererProps
  extends React.HTMLAttributes<HTMLElement> {
  html: string;
}

const ContentRendererInner: React.FC<ContentRendererProps> = (
  props: ContentRendererProps
) => {
  return (
    <div
      className={props.className}
      dangerouslySetInnerHTML={{ __html: props.html }}
    ></div>
  );
};

export const ContentRenderer = styled(ContentRendererInner)<
  ContentRendererProps
>`
  p {
    margin: 1em;
  }
`;

import { styled } from 'linaria/react';
import React from 'react';
import { Borders } from '../../Shared/Borders/Borders';
import { ContainerCustom } from '../../Shared/ConatinerCustom/ContainerCustom';
import { HeadingWithNote } from '../../Shared/HedingWithNote/HeadingWithNote';
import { Section } from '../../Shared/Section/Section';
import { HeadingFour } from '../../Shared/Typography/HeadingFour/HeadingFour';
import { KmwGlobalImage } from './components/KmwGlobalImage';

export interface KmwGlobalProps {
  className?: string;
}

const KmwGlobalInner = (props: KmwGlobalProps) => {
  return (
    <Section className={props.className}>
      <ContainerCustom>
        <Borders bottomRight topRight>
          <div className="content">
            <HeadingWithNote heading="KMG - KRAV MAGA GLOBAL" />
            <HeadingFour>
              Vznik civilnej varianty a história dnešnej organizácie Krav Maga
              Global
            </HeadingFour>
            <p>
              Po tom, ako Imi odišiel do dôchodku, rozhodol sa systém preklopiť
              do civilnej sféry. Metóda bola už určená pre každého, to znamená
              pre ženy aj mužov, chlapcov a dievčatá. Imi založil dve tréningové
              centrá – jednu v Tel Avive a druhú v Netanya. Prvý inštruktorský
              kurz sa konal v roku 1972 a od tej doby sa Krav Maga sa rozšírila
              nielen v Izraeli, ale aj vo svete.
            </p>
            <p>
              V roku 1978 Imi založil IKMA, aby v rámci tejto associácie bola
              Krav Maga rozšírená v Izraeli. Neskôr požiadal svojho študenta
              Eyala Yanilova, aby vypracoval nové Curriculum pre systém a
              neskoršie založil IKMF, ktorá mala byť v tej dobe odpoveďou na
              globálne potreby zväčšujúcej sa federácie, najmä pri rozširovaní
              vo svete. Imi opustil tento svet ako 88 ročný v roku 1998. Až do
              najvyššieho veku Imi dohliadal na vývoj systému, konzultoval a
              konfrontoval jednotlivé časti Krav Magy.
            </p>
            <p>
              V roku 2010 Eyal Yanilov založil Krav Maga Global, ktorá má za
              cieľ združovať jednotlivé krajiny a inštruktorov a študentov pod
              jeho vedením.
            </p>
          </div>
        </Borders>
        <KmwGlobalImage />
      </ContainerCustom>
    </Section>
  );
};

export const KmwGlobal = styled(KmwGlobalInner)`
  padding: 20px 0;
  .content {
    padding: 30px 0;
  }

  p {
    margin-top: 20px;
  }
`;

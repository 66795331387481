import { useStaticQuery, graphql } from 'gatsby';
import { Question } from '../KmwVisualFaq';

export const useKmwQuestions = () => {
  const {
    allMarkdownRemark: { nodes }
  } = useStaticQuery(graphql`
    query useVisualFaqData {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/data/kmw/visualfaq/.*/" } }
      ) {
        nodes {
          html
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(quality: 90, maxWidth: 800) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `);
  const questions: { [key: string]: Question } = {};
  nodes
    .map(node => ({ ...node.frontmatter, html: node.html }))
    .forEach((question: Question) => (questions[question.title] = question));

  return questions;
};

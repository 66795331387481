import { styled } from 'linaria/react';
import React from 'react';
import { ContentRenderer } from '../../../Shared/ContentRenderer/ContentRenderer';
import { theme } from '../../../Shared/Theme/Theme';
import { HeadingFour } from '../../../Shared/Typography/HeadingFour/HeadingFour';
import { HeadingThree } from '../../../Shared/Typography/HeadingThree/HeadingThree';

export interface KmwInstructorProfileProps {
  className?: string;
  name: string;
  position: string;
  text: string;
}

const KmwInstructorProfileInner = (props: KmwInstructorProfileProps) => {
  const { name, position, text } = props;
  return (
    <article className={props.className}>
      <HeadingFour
        style={{
          marginBottom: 10
        }}
      >
        {name}
        {position && (
          <>
            {' '}
            /{' '}
            <span
              style={{
                color: theme.color.primary
              }}
            >
              {position}
            </span>
          </>
        )}
      </HeadingFour>
      <ContentRenderer html={text} />
    </article>
  );
};

export const KmwInstructorProfile = styled(KmwInstructorProfileInner)`
  padding-bottom: 30px;
`;

import { useStaticQuery, graphql } from 'gatsby';
import { FluidObject } from 'gatsby-image';

export interface Instructor {
  image: FluidObject;
  level: string;
  name: string;
  description: string;
  text: string;
  position: string;
  order: number;
}

export const useKmwInstructors = (): Instructor[] => {
  const {
    allMarkdownRemark: { nodes }
  } = useStaticQuery(graphql`
    query useKmwInstructors {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/data/kmw/instructors/.*/" } }
      ) {
        nodes {
          frontmatter {
            name
            level
            description
            position
            order
            image {
              childImageSharp {
                fluid(quality: 90, maxWidth: 800) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          html
        }
      }
    }
  `);

  const instructors = nodes
    .map(node => ({
      ...node.frontmatter,
      image: node.frontmatter.image.childImageSharp.fluid,
      text: node.html
    }))
    .sort((a, b) => a.order - b.order);
  return instructors;
};

import { Grid, Hidden } from '@material-ui/core';
import { styled } from 'linaria/react';
import React from 'react';
import { KMWSide } from '../../Landing/PerkSection/components/KMWSide';
import { ContainerCustom } from '../../Shared/ConatinerCustom/ContainerCustom';
import { Section } from '../../Shared/Section/Section';
import { theme } from '../../Shared/Theme/Theme';
import { HeadingTwo } from '../../Shared/Typography/HeadingTwo/HeadingTwo';
import { InstructorCard } from './components/InstructorCard';
import { InstructorsKMWSide } from './components/InstructorsKMWSide';
import { useKmwInstructors } from '../hooks/useKmwInstructors';

export interface KMWInstructorsProps {
  className?: string;
}

const KMWInstructorsInner = (props: KMWInstructorsProps) => {
  const instructors = useKmwInstructors();

  return (
    <Section className={props.className}>
      <Hidden lgDown>
        <InstructorsKMWSide />
      </Hidden>
      <ContainerCustom>
        <HeadingTwo
          style={{
            marginBottom: 20
          }}
        >
          INŠTRUKTORI A KLUB KMG WARRIOR
        </HeadingTwo>
        <Grid container spacing={4}>
          {instructors.map(instructor => (
            <Grid key={instructor.name} item xs={12} md={4}>
              <InstructorCard {...instructor} />
            </Grid>
          ))}
        </Grid>
      </ContainerCustom>
      <div className="section-bg" />
    </Section>
  );
};

export const KMWInstructors = styled(KMWInstructorsInner)`
  padding: 40px 0;
  margin-top: 60px;
  text-align: center;
  position: relative;
  display: flex;

  .section-bg {
    background: ${theme.color.gray};
    height: 100%;
    width: 100%;
    display: flex;
    flex: 1;
    position: absolute;
    z-index: -3;
    top: 0;
  }
`;

import { Hidden } from '@material-ui/core';
import GatsbyImage from 'gatsby-image';
import { styled } from 'linaria/react';
import React from 'react';
import { ContentRenderer } from '../../../Shared/ContentRenderer/ContentRenderer';
import { Question } from '../KmwVisualFaq';

export interface CurrentQuestinoProps
  extends React.HTMLAttributes<HTMLElement> {
  question: Question;
}

const CurrentQuestinoInner: React.FC<CurrentQuestinoProps> = (
  props: CurrentQuestinoProps
) => {
  return (
    <article className={props.className}>
      <div className="image">
        <Hidden smDown>
          <GatsbyImage fluid={props.question.image.childImageSharp.fluid} />
        </Hidden>
      </div>
      <h1>{props.question.title}</h1>
      <ContentRenderer html={props.question.html} />
    </article>
  );
};

export const CurrentQuestino = styled(CurrentQuestinoInner)<
  CurrentQuestinoProps
>`
  width: 100%;
  h1 {
    font-size: 2.4rem;
    text-transform: uppercase;
    margin: 2rem 0;
  }

  p {
    margin: 1em auto;
  }
`;

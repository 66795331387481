import { Grid } from '@material-ui/core';
import GatsbyImage from 'gatsby-image';
import { styled } from 'linaria/react';
import React from 'react';
import { Borders } from '../../Shared/Borders/Borders';
import { ContainerCustom } from '../../Shared/ConatinerCustom/ContainerCustom';
import { HeadingWithNote } from '../../Shared/HedingWithNote/HeadingWithNote';
import { Section } from '../../Shared/Section/Section';
import { HeadingFour } from '../../Shared/Typography/HeadingFour/HeadingFour';
import { HeadingTwo } from '../../Shared/Typography/HeadingTwo/HeadingTwo';
import { useKmwHistoryImages } from './hooks/useKmwHistoryImages';

export interface KmwHistoryProps {
  className?: string;
}

const KmwHistoryInner = (props: KmwHistoryProps) => {
  const images = useKmwHistoryImages();
  return (
    <Section className={props.className}>
      <ContainerCustom>
        <HeadingTwo style={{ textAlign: 'center' }}>
          HISTÓRIA KRAV MAGA
        </HeadingTwo>
        <Borders bottomLeft>
          <div className="content">
            <HeadingWithNote heading="SPOZNAJ SVETOZNÁMY SYSTÉM SEBAOBRANY" />
            <article>
              <HeadingFour>Kto je Imrich Lichtenfeld „Imi“?</HeadingFour>
              <p>
                Zakladateľom Krav Magy je Imrich Lichtenfeld „Imi“, ktorý sa
                narodil v Budapešti a následne prežil svoje detstvo a
                dospievanie v Bratislave. Obrovský vplyv mal na Imi-ho jeho otec
                Samuel Lichtenfeld, ktorý ako 13 ročný ušiel a pridal sa k
                cirkusu. Tu spoznal svet zápasenia a demonštrácie telesnej
                zdatnosti a sily. Po 20 rokoch pôsobenia sa v cirkuse Samuel
                Lichtenfeld presťahoval do Bratislavy, kde založil aj prvý
                ťažkoatletický klub „Hercules“. V Bratislave sa Samuel pridal k
                polícii a dosiahol až funkciu hlavného detektíva. Vo svojej
                branži bol veľmi uznávaný najmä za výsledky, keď počas jeho
                pôsobenia vyriešil najviac vrážd a dolapil najviac násilných
                kriminálnikov. Imi bol už ako dieťa vďaka svojmu otcovi vedený
                rôznym športom a fyzickým aktivitám. Ako prvé začal excelovať v
                plávaní, následne v gymnastike, zápasení a v boxe. Ako 19 ročný
                vyhral v roku 1929 medzinárodné preteky v zápasení a ešte toho
                roku vyhral aj národnú súťaž v boxe a medzinárodnú súťaž v
                gymnastike. V nasledujúcich rokoch Imi pokračoval ďalej aj ako
                úspešný športovec ale aj ako tréner.
              </p>
            </article>
            <article>
              <HeadingFour>Ťažké začiatky</HeadingFour>
              <p>
                V druhej polovici tridsiatich rokov sa politická situácia
                zmenila a Imi sa sám alebo so svojimi kamarátmi dostal do
                nespočetných fyzických konfliktov s prívržencami antisemitských
                skupín. Tieto násilné strety v období medzi 1936 a 1940 kde
                športom zocelený Imi bol nútený sa zúčastniť bitiek zanechali
                hlboké stopy v Imi-m a môžeme kľudne povedať že tu niekde sú
                korene Krav-Magy.
              </p>
              <p>
                V roku 1940 sa Imi stal so svojimi aktivitami tŕňom v oku
                antisemitizmu nakloneným úradom a tak sa rozhodol opustiť svoj
                domov, rodinu a priateľov a pridal sa na loď plnú imigrantov,
                ktorej sa ako poslednej podarilo ujsť nacistickému zovretiu.
                Príbeh riečnej lode Pentcho bol zachytený aj v knihe Johna
                Birmana – Odyssey. Počas dlhej cesty kde Imi veľakrát
                zachraňoval ľudí, batožinu, ale aj veľmi cenné jedlo spadnuté do
                vody dostal Imi zápaľ, ktorý ho skoro stál jeho život. Po tom
                ako vybuchol kotol na lodi, Imi so svojimi štyrmi kamarátmi sa
                rozhodli, že preveslujú na ostrov Kréta. Imi vtedy ignoroval
                zápal ucha, aj prosbu priateľov. Po piatich dňoch veslovania ich
                vyzdvihla britská vojenská loď, ktorá ich dopravila do
                Alexandrie v Egypte. Imi musel prekonať viacero vážnych operácií
                a bojoval s chorobou o život. Po zotavení vstúpil do Českej
                Légie a bojoval pod Britským velením.
              </p>
            </article>
            <article>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <GatsbyImage className="kmw-image" fluid={images.leftPhoto} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <GatsbyImage
                    className="kmw-image"
                    fluid={images.rightPhoto}
                  />
                </Grid>
              </Grid>
            </article>
            <article>
              <HeadingFour>
                Imrich Lichtenfeld ako hlavný inštruktor Krav Maga pre IDF
              </HeadingFour>
              <p>
                Po prepustení zo služby Imimu vyhoveli v jeho žiadosti a v roku
                1942 konečne mohol vstúpiť na územie, ktoré sa volalo v tej dobe
                Palestína. Keďže v tej dobe už viacero z Imiho priateľov slúžilo
                v Haganah (predchodca IDF (Israel Defense Forces)) veľmi rýchlo
                sa stal vďaka svojmu talentu a odporúčaniam inštruktorom. Od
                roku 1944 Imi vďaka jeho skúsenostiam začal trénovať bojovníkom
                najmä v oblastiach: fyzická príprava, plávanie, zápasenie,
                použitie noža a obranu proti útokom nožom. Vycvičil viacerých
                elitných bojovníkov vtedajšej Haganah a Palmach (úderová
                jednotka Haganah a predchodca špeciálnych jednotiek IDF).
              </p>
              <p>
                Po založení štátu Izrael v roku 1948 a založení IDF sa stal Imi
                hlavným inštruktorom pre fyzickú prípravu a Krav Maga. Imi
                slúžil v IDF približne 20 rokov, počas ktorých rozvíjal a
                dolaďoval jeho unikáty systém pre sebeobranu. Imi osobne
                vycvičil za toto obdobie špičku bojovníkov pre izraelské
                špeciálne jednotky a kvalifikoval veľa generácií Krav Maga
                inštruktorov.
              </p>
              <p>
                Imiho metóda musela vyhovieť rôznym požiadavkám zo strany IDF.
                Musela byť taká, ktorá sa dá ľahko naučiť, jednoducho aplikovať,
                aby všetci vojaci, či už vojaci – úradníci alebo špičkoví
                bojovníci so svojimi zdatnosťami a požiadavkami na daný post si
                mohli systém osvojiť za čo najkratšiu dobu a s maximálnou
                účinnosťou nasadenia týchto vedomostí na danom leveli. Na systém
                bola ale kladená aj tá požiadavka, aby techniky boli
                prevediteľné aj v tých najstresovejších podmienkach.
              </p>
            </article>
          </div>
        </Borders>
      </ContainerCustom>
    </Section>
  );
};

export const KmwHistory = styled(KmwHistoryInner)`
  padding: 20px 0;
  article {
    margin-bottom: 30px;
  }

  p {
    margin-top: 20px;
  }

  .content {
    padding: 10px 0;
  }

  .kmw-image {
    height: 300px;
  }
`;

import { useStaticQuery, graphql } from 'gatsby';

export const useKmwHistoryImages = () => {
  const { leftPhoto, rightPhoto } = useStaticQuery(graphql`
    query useKmwHistoryImages {
      leftPhoto: file(
        relativePath: { eq: "images/kmw/history/history-left.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      rightPhoto: file(
        relativePath: { eq: "images/kmw/history/history-right.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return {
    leftPhoto: leftPhoto.childImageSharp.fluid,
    rightPhoto: rightPhoto.childImageSharp.fluid
  };
};
